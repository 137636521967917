import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const Careers = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Careers'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="careers" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>Careers & Open Roles</h1>
                              <h5>We're building something awesome, and want you to join!</h5>
                              <p>
                                   ListenLayer is always looking for the best talent. Whether you're in engineering, data science, marketing, sales,
                                   or customer support - come work with a team of rockstars! Send you resume for any open position to{' '}
                                   <a href="mailto:support@listenlayer.com" target="_blank" rel="noreferrer">
                                        support@listenlayer.com
                                   </a>{' '}
                                   and we'll respond to you inquiry.
                              </p>
                         </div>
                         <div className="platform-intro-content">
                              <div className="sup-title">
                                   <h3>Open Roles</h3>
                              </div>
                              <h4>Data Engineer</h4>
                              <p>
                                   Seeking a Data Engineer to design and build robust data pipelines, ensuring data accuracy and efficiency. Ideal for
                                   those with strong SQL and Python skills, eager to innovate in data processing and analytics.
                              </p>
                              <h4>Data Scientist</h4>
                              <p>
                                   Join as a Data Scientist to drive insights through predictive modeling and data analysis. Perfect for analytical
                                   thinkers with expertise in statistical tools and a passion for turning data into actionable strategies.
                              </p>
                              <h4>Senior Software Engineer</h4>
                              <p>
                                   We're looking for a Senior Software Engineer to lead the development of high-quality software solutions. This role
                                   is ideal for experienced coders with a deep understanding of various programming languages and software
                                   architecture.
                              </p>
                              <h4>Marketing Manager</h4>
                              <p>
                                   Apply for our Marketing Manager position to strategize and execute compelling marketing campaigns. A great
                                   opportunity for creative individuals with strong leadership skills and a proven track record in digital marketing.
                              </p>
                              <h4>Business Development Rep</h4>
                              <p>
                                   As a Business Development Rep, you'll identify new business opportunities and build lasting client relationships.
                                   Suited for dynamic sales professionals with excellent communication skills and a drive to achieve targets.
                              </p>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default Careers
